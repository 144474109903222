import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { debounce } from 'lodash'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
// import Divider from '@material-ui/core/Divider'
// import IconButton from '@material-ui/core/IconButton'
// import SearchIcon from '@material-ui/icons/Search'
// import MenuBookTwoToneIcon from '@material-ui/icons/MenuBookTwoTone'
// import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone'
// import MenuIcon from '@material-ui/icons/Menu'
import TwitterIcon from '@material-ui/icons/Twitter'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { Hidden } from '@material-ui/core'

import { log } from '../Utils/Console'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
    // width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    textOverflow: 'ellipsis'
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  icon: {
    marginLeft: 10
  }
}))

export default function SearchBar() {
  const classes = useStyles()

  const [input, setInput] = useState('')

  const handleChange = event => {
    setInput(event.target.value)
  }

  const { tid } = useParams()
  const [tweetId, setTweetId] = useState()

  const debouncedUpdater = debounce(text => {
    log('%c[SearchBar] processing input', 'font-weight:bold; color:blue')
    const re = /twitter\.com\/[\w\d]+\/status\/(\d+)$/
    const isTweetURL = re.test(text)
    let newId = ''
    if (isTweetURL) {
      newId = text.match(re).pop()
    }
    setTweetId(newId)
    // console.log(newId)
  }, 500)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCallback = useCallback(t => debouncedUpdater(t), [tid])

  useEffect(() => {
    // console.log('debounce')
    updateCallback(input)
  }, [updateCallback, input, tid])

  return (
    <>
      {tweetId && <Redirect to={`/tweet/${tweetId}`} />}
      <Paper square variant="outlined" className={classes.root}>
        <Hidden xsDown>
          <TwitterIcon color="primary" className={classes.icon} />
          <ArrowForwardIcon className={classes.icon} />
        </Hidden>
        <InputBase
          className={classes.input}
          value={input}
          onChange={handleChange}
          placeholder="Paste a twitter URL, ex: https://twitter.com/ArianaGrande/status/866849021519966208"
          inputProps={{ 'aria-label': 'paste a tweet URL', style: { textOverflow: 'ellipsis' } }}
        />

        {/* 
        <IconButton type="submit" className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
        */}
        {/* <Divider className={classes.divider} orientation="vertical" /> */}
        {/*
        <IconButton
          color="primary"
          className={classes.iconButton}
          aria-label="directions"
          component={RouterLink}
          to="/about"
        >
          <HelpTwoToneIcon />
        </IconButton>
        */}
        {/*
        <IconButton
          color="primary"
          className={classes.iconButton}
          aria-label="directions"
          component={RouterLink}
          to="/about"
        >
          <MenuIcon />
        </IconButton>
        */}
      </Paper>
    </>
  )
}
