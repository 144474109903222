import React from 'react'

import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const PaddedTypography = withStyles(theme => ({
  root: {
    padding: theme.spacing(4, 4, 1, 4)
  }
}))(Typography)

function ErrorPage() {
  return (
    <>
      <PaddedTypography color="secondary">Oops! What just happened?</PaddedTypography>
      <PaddedTypography color="textSecondary">
        This means that you found a bug on my code, or that one of the cloud serverless services or
        APIs has hit its rate limit because this is becoming popular or because it is being abused.
      </PaddedTypography>
      <PaddedTypography color="textSecondary">
        In any case, not cool. Please let me know about it!
      </PaddedTypography>
    </>
  )
}

export default ErrorPage
