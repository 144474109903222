import { createMuiTheme } from '@material-ui/core/styles'
import { lightBlue, blueGrey } from '@material-ui/core/colors'
import { fade } from '@material-ui/core/styles/colorManipulator'

const theme = createMuiTheme({
  palette: {
    primary: { main: lightBlue[500] },
    text: {
      secondary: blueGrey[500]
    },
    background: {
      default: '#fff'
    },
    action: {
      hover: fade(lightBlue[500], 0.08),
      active: fade(lightBlue[500], 0.54),
      hoverOpacity: 0.04,
      selected: fade(lightBlue[500], 0.16),
      selectedOpacity: 1,
      focus: fade(lightBlue[500], 0.12),
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      'IBM Plex Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
})

export default theme
