import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import theme from './components/Theme'

import {
  NeuralNetworksProvider,
  useNeuralNetworksContext
} from './components/Context/NeuralNetworks'

import TweetPage from './components/Pages/TweetPage'
import AboutPage from './components/Pages/AboutPage'
import LoadingPage from './components/Pages/LoadingPage'

import tweets from './components/API/tweets.json'

function PrivateRoute({ component: Component, ...rest }) {
  const [, hasModels] = useNeuralNetworksContext()
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={props =>
        hasModels === true ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Component {...props} />
        ) : (
          <LoadingPage />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType
}

PrivateRoute.defaultProps = {
  component: LoadingPage
}

function App() {
  const [start] = useState(tweets[Math.floor(Math.random() * tweets.length)])
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet title="Twitter affect analysis demonstration" />
      <NeuralNetworksProvider>
        <Router>
          <Switch>
            <PrivateRoute exact path="/examples/:tid">
              <TweetPage isLive={false} />
            </PrivateRoute>
            <PrivateRoute exact path="/tweet/:tid">
              <TweetPage isLive />
            </PrivateRoute>
            <Route exact path="/about">
              <AboutPage />
            </Route>
            <Route path="*">
              <Redirect to={`/examples/${start.data.id}`} />
            </Route>
          </Switch>
        </Router>
      </NeuralNetworksProvider>
    </ThemeProvider>
  )
}

export default App
