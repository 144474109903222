/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

import { Hidden, Grid, Typography, Fade, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined'

import Legend from './Legend'
import Footer from '../Twitter/Footer'
import TryAnotherDialog from './TryAnotherDialog'

import emotions from '../../emotions.json'

function percentage(value) {
  return new Intl.NumberFormat('en-US', {
    style: 'percent'
  }).format(value)
}

function Value(props) {
  const { children } = props
  const stronglyInferred = children >= 0.5
  const weaklyInferred = children < 0.3
  let color = 'textPrimary'
  if (weaklyInferred) color = 'textSecondary'
  if (stronglyInferred) color = 'primary'
  return (
    <Fade in>
      <Typography variant="h4" component="p" color={color}>
        {percentage(children)}
      </Typography>
    </Fade>
  )
}

Value.propTypes = {
  children: PropTypes.node.isRequired
}

const useStyles = makeStyles(theme => ({
  section: {
    padding: theme.spacing(2),
    textAlign: 'left',
    [theme.breakpoints.down('md')]: { padding: theme.spacing(4) },
    [theme.breakpoints.down('xs')]: { textAlign: 'center' }
  },
  container: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: { justifyContent: 'flex-start' }
  },
  item: { padding: theme.spacing(2, 0) },
  icon: {
    height: theme.spacing(2),
    width: theme.spacing(2)
  },
  button: { color: 'white', borderRadius: theme.spacing(4), marginTop: theme.spacing(2) }
}))

function Summary(props) {
  const classes = useStyles()
  const { predictions } = props

  const dim = predictions.length
  const hasPredictions = dim > 0

  // console.log('Summary')
  // console.log(predictions)

  /* console.log(
    emotions.reduce((prev, next) => {
      return { ...prev, [next]: 0 }
    }, {})
  ) */

  // prediction is array[prediction for sentences]
  // prediction for sentence is {emotion: value, emotion: value}

  // Count
  const maxed = {}
  const count = {}

  predictions.forEach(prediction => {
    Object.entries(prediction).forEach(([key, value]) => {
      if (maxed[key]) {
        // maxed[key] = Math.max(maxed[key], value)
        maxed[key] += value / dim
      } else {
        maxed[key] = value / dim
        count[key] = 0
      }
      // update counts
      if (value >= 0.5) {
        count[key] += 1
      }
    })
  })
  // console.log(maxed)
  // console.log(count)

  const isMajority = c => c >= dim / 2

  // Dialog
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <section className={classes.section}>
      {hasPredictions && <Typography variant="subtitle2">Aggregated analysis:</Typography>}
      <Grid container className={classes.container}>
        {hasPredictions &&
          emotions.map((el, i) => (
            <Grid key={el} item xs={4} sm={3} md={2} lg={1} className={classes.item}>
              <Fade in style={{ transitionDelay: `${50 * i}ms` }}>
                <Typography variant="caption" component="p">
                  {el}
                </Typography>
              </Fade>
              {Array.from(Array(count[el])).map((c, id) => (
                <Fade key={`yes-${id}`} in style={{ transitionDelay: `${50 + 50 * i}ms` }}>
                  <FiberManualRecordIcon
                    fontSize="small"
                    className={classes.icon}
                    color={isMajority(count[el]) ? 'primary' : 'inherit'}
                  />
                </Fade>
              ))}
              {Array.from(Array(dim - count[el])).map((d, id) => (
                <Fade key={`no-${id}`} in style={{ transitionDelay: `${50 + 50 * i}ms` }}>
                  <FiberManualRecordOutlinedIcon
                    fontSize="small"
                    color="disabled"
                    className={classes.icon}
                  />
                </Fade>
              ))}

              <Fade in style={{ transitionDelay: `${100 + 50 * i}ms` }}>
                <Value>{maxed[el]}</Value>
              </Fade>
            </Grid>
          ))}
        {hasPredictions && <Legend />}
      </Grid>
      <Footer />
      <Hidden lgUp>
        <Button
          onClick={handleClickOpen}
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
        >
          Try another Tweet
        </Button>
        <TryAnotherDialog open={open} onClose={handleClose} />
      </Hidden>
    </section>
  )
}

Summary.propTypes = {
  predictions: PropTypes.arrayOf(PropTypes.shape({}))
}

Summary.defaultProps = {
  // predictions: [emotions.reduce((prev, next) => ({ ...prev, [next]: 0 }), {})]
  predictions: []
}

export default Summary
