import React from 'react'
import PropTypes from 'prop-types'
import { /* useLocation, matchPath, */ useParams } from 'react-router-dom'

import { Hidden, Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Tweet, { SearchBar, ExampleList /* About, Legend */ } from '../Twitter'
import Summary from '../Prediction/Summary'
import Ranking from '../Prediction/Ranking'

import useTwitter from '../API/useTwitter'

import ErrorPage from './ErrorPage'

// import emotions from '../../emotions.json'
// import embeddings from '../Prediction/embeddings.json'

const useStyles = makeStyles(theme => ({
  main: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      /* minHeight: 'unset' */
    }
  },
  grow: {
    flexGrow: 1,
    padding: theme.spacing(1, 1)
  },
  container: {
    marginLeft: 0,
    [theme.breakpoints.down('md')]: { marginLeft: 0 }
  },
  section: {
    // background: 'grey'
  },
  item: { padding: theme.spacing(2, 0) }
}))

function TweetPage(props) {
  const classes = useStyles()
  const { isLive } = props

  // Get location and decide
  /* const location = useLocation()
  const isLive = matchPath(location.pathname, {
    path: '/tweet/:tid',
    exact: true,
    strict: false
  }) */

  // Get ID from route
  const { tid } = useParams()
  // console.log(tid)

  const [tweet, sentences, predictions, error] = useTwitter(tid, isLive)

  return error ? (
    <ErrorPage />
  ) : (
    <main className={classes.main}>
      <SearchBar />
      <section className={classes.grow}>
        <Grid container>
          <Grid item xs>
            <Container maxWidth="sm" className={classes.container}>
              <Tweet
                data={tweet.data}
                includes={tweet.includes}
                sentences={sentences}
                predictions={predictions}
              />
            </Container>
          </Grid>

          <Hidden smDown>
            <Grid item xs={4}>
              {/* <About />
              <Legend /> */}
              <Ranking predictions={predictions} sentences={sentences} />
            </Grid>
          </Hidden>

          <Hidden mdDown>
            <Grid item xs={3}>
              <ExampleList selected={tid} />
            </Grid>
          </Hidden>
        </Grid>
      </section>
      <Hidden mdUp>
        <Ranking predictions={predictions} sentences={sentences} />
      </Hidden>
      <Summary predictions={predictions?.length ? predictions : undefined} />
    </main>
  )
}

TweetPage.propTypes = {
  isLive: PropTypes.bool.isRequired
}

export default TweetPage
