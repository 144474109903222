import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Card, CardContent, Grid, Avatar, Typography, Divider, Fade } from '@material-ui/core'
import TwitterIcon from '@material-ui/icons/Twitter'
import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone'
import ChatBubbleTwoToneIcon from '@material-ui/icons/ChatBubbleTwoTone'
import RepeatTwoToneIcon from '@material-ui/icons/RepeatTwoTone'
import FormatQuoteTwoToneIcon from '@material-ui/icons/FormatQuoteTwoTone'

import { makeStyles } from '@material-ui/core/styles'

import Skeleton from '@material-ui/lab/Skeleton'

import Tooltip, { TooltipContent } from './Tooltip'

import emotions from '../../emotions.json'

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRadius: 8,
    // marginTop: '10vh',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { marginTop: theme.spacing(1.5) }
  },
  cardAction: {
    height: '100%'
  },
  avatar: {
    width: 48,
    height: 48
  },
  twitter: {
    marginLeft: 'auto',
    color: theme.palette.primary.light
  },
  name: {
    // fontSize: 16
  },
  quote: {
    paddingBottom: '16px !important',
    paddingTop: 0,
    '& em': {
      fontStyle: 'normal',
      color: theme.palette.primary.main
    }
  },
  metrics: {
    display: 'flex',
    alignItems: 'center'
  },
  metric: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    fontSize: theme.typography.button.fontSize,
    color: theme.palette.text.secondary
  }
}))

function format(value) {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short'
  }).format(value)
}

function isHighlighted(values) {
  const threshold = 0.5
  return !!Object.values(values).filter(el => el >= threshold).length
}

const useSentenceStyles = makeStyles(theme => ({
  sentence: {
    marginRight: theme.spacing(1),
    cursor: 'help',
    userSelect: 'none'
  },
  highlighted: {
    borderBottom: '4px solid whitesmoke',
    transition: 'border 70ms ease-in, background-color 140ms ease-in',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main
    }
  }
}))

function Sentence(props) {
  const { children, anchorEl, predictions, id, ...rest } = props
  const classes = useSentenceStyles()
  return (
    <Tooltip
      placement="bottom"
      leaveDelay={100}
      enterNextDelay={150}
      // enterTouchDelay={0}
      arrow
      PopperProps={{ anchorEl }}
      title={isHighlighted(predictions) ? <TooltipContent id={id} emotions={predictions} /> : ''}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <Typography
        color="textPrimary"
        variant="body1"
        component="span"
        className={clsx(classes.sentence, isHighlighted(predictions) && classes.highlighted)}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    </Tooltip>
  )
}

Sentence.propTypes = {
  children: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  predictions: PropTypes.shape({}),
  anchorEl: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}

Sentence.defaultProps = {
  predictions: emotions.reduce((p, c) => ({ ...p, [c]: 0 }), {}),
  anchorEl: null
}

function hasMetrics(metrics) {
  const {
    retweet_count: retweets,
    reply_count: replies,
    like_count: likes,
    quote_count: quotes
  } = metrics
  return retweets || replies || likes || quotes
}

function Tweet(props) {
  const { data, includes, sentences, predictions } = props
  const { author_id: authorId, public_metrics: metrics } = data
  const {
    retweet_count: retweets,
    reply_count: replies,
    like_count: likes,
    quote_count: quotes
  } = metrics
  const { users } = includes
  const author = users.find(u => u.id === authorId)
  const { username: authorUserName, name: authorName, profile_image_url: authorAvatarURL } = author
  const classes = useStyles()
  const ref = useRef()

  return (
    <Card variant="outlined" className={classes.card} ref={ref}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item>
            <Avatar src={`${authorAvatarURL}`} alt={authorName} className={classes.avatar} />
          </Grid>
          <Grid item>
            <Typography
              component="div"
              variant="subtitle2"
              color="textPrimary"
              className={classes.name}
            >
              <b>{authorName || <Skeleton variant="text" width={128} />}</b>
            </Typography>
            <Typography component="div" variant="body2" color="textSecondary">
              {authorUserName ? `@${authorUserName}` : <Skeleton variant="text" width={72} />}
            </Typography>
          </Grid>
          <Grid item className={classes.twitter}>
            <TwitterIcon />
          </Grid>
        </Grid>
      </CardContent>
      <CardContent className={classes.quote}>
        {sentences.length && predictions.length
          ? sentences.map((sentence, i) => (
              <Fade in key={sentence} style={{ transitionDelay: `${100 * i}ms` }}>
                <Sentence
                  anchorEl={ref.current}
                  id={i}
                  predictions={predictions ? predictions[i] : undefined}
                >
                  {sentence}
                </Sentence>
              </Fade>
            ))
          : [0, 1, 2, 3].map(i => (
              <Skeleton key={`sk-${i}`} variant="text" width={i < 3 ? '100%' : '75%'} />
            ))}
      </CardContent>
      <Divider />

      {hasMetrics(metrics) ? (
        <CardContent className={classes.metrics}>
          <Typography component="span" variant="body2" color="textSecondary">
            <strong>{format(likes)}</strong>
          </Typography>
          <FavoriteTwoToneIcon fontSize="small" className={classes.metric} alt="likes" />
          <Typography component="span" variant="body2" color="textSecondary">
            <strong>{format(retweets)}</strong>
          </Typography>
          <RepeatTwoToneIcon fontSize="small" className={classes.metric} alt="retwets" />
          <Typography component="span" variant="body2" color="textSecondary">
            <strong>{format(replies)}</strong>
          </Typography>
          <ChatBubbleTwoToneIcon fontSize="small" className={classes.metric} alt="replies" />
          <Typography component="span" variant="body2" color="textSecondary">
            <strong>{format(quotes)}</strong>
          </Typography>
          <FormatQuoteTwoToneIcon fontSize="small" className={classes.metric} alt="quotes" />
        </CardContent>
      ) : (
        <CardContent>
          <Skeleton width="30%" height={14} />
        </CardContent>
      )}
    </Card>
  )
}

Tweet.propTypes = {
  data: PropTypes.shape({
    author_id: PropTypes.string,
    public_metrics: PropTypes.shape({
      retweet_count: PropTypes.number,
      reply_count: PropTypes.number,
      like_count: PropTypes.number,
      quote_count: PropTypes.number
    })
  }),
  includes: PropTypes.shape({
    users: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  sentences: PropTypes.arrayOf(PropTypes.string),
  predictions: PropTypes.arrayOf(PropTypes.shape({}))
}

Tweet.defaultProps = {
  data: {
    author_id: '0',
    public_metrics: {
      retweet_count: 0,
      reply_count: 0,
      like_count: 0,
      quote_count: 0
    }
  },
  includes: {
    users: [
      {
        id: '0',
        username: undefined,
        name: undefined,
        profile_image_url: undefined
      }
    ]
  },
  sentences: [],
  predictions: []
}

export default Tweet
