import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Typography, Button, Fade } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const PaddedTypography = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))(Typography)

const SpacedButton = withStyles(theme => ({
  root: {
    margin: theme.spacing(2)
  }
}))(Button)

const About = () => (
  <Fade in>
    <Box component="section" textAlign="right">
      <PaddedTypography variant="overline" align="right" component="h2">
        About
      </PaddedTypography>
      <PaddedTypography variant="body2" color="textSecondary" align="right">
        Multi-languange sentiment analysis with neural networks and zero shot cross lingual transfer
        learning.
      </PaddedTypography>
      <SpacedButton
        color="primary"
        aria-label="description of the artificial intelligence method used"
        component={RouterLink}
        variant="outlined"
        size="small"
        to="/about"
      >
        Tell me about it
      </SpacedButton>
    </Box>
  </Fade>
)

export default About
