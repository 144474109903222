import axios from 'axios'

import { log } from '../Utils/Console'

const ENDPOINT = 'https://us-central1-laser-sentence-embeddings.cloudfunctions.net/getTweet'

// Fetch logic
const getRequest = async (req, source) => {
  let response
  try {
    const result = await axios.get(`${ENDPOINT}?id=${req}`, {
      cancelToken: source.token,
      headers: { 'X-API-KEY': process.env.REACT_APP_APIKEY }
    })
    if (result.data) {
      response = result.data
    }
  } catch (exception) {
    log(exception)
  }
  return response
}

export default getRequest
