import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Tooltip } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

// import { log } from '../Utils/Console'

const emoji = {
  anger: '😡',
  anticipation: '😉',
  disgust: '🤮',
  fear: '😨',
  joy: '😂',
  love: '🥰',
  optimism: '😊',
  pessimism: '😞',
  sadness: '😥',
  surprise: '😯',
  trust: '😌'
}

// console.log(emoji)

function percentage(value) {
  return new Intl.NumberFormat('en-US', {
    style: 'percent'
  }).format(value)
}

const SolidTooltip = withStyles(theme => ({
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(1, 4, 2)
  }
}))(Tooltip)

function TooltipContent(props) {
  const { emotions, id } = props

  const threshold = 0.5
  const displayable = Object.entries(emotions)
    .filter(([, val]) => val >= threshold)
    .sort(([, a], [, b]) => b - a)

  // log('%c[TooltipContent] Displayable values are:', 'font-weight:bold; color:green')
  // log(displayable)

  return (
    <>
      <Typography variant="overline">{`Sentence ${id + 1} shows:`}</Typography>
      {displayable.map(([emotion, value]) => (
        <Typography variant="body2">{`${percentage(value)} probability of ${
          emoji[emotion]
        } ${emotion} `}</Typography>
      ))}
    </>
  )
}

TooltipContent.propTypes = {
  emotions: PropTypes.shape({}).isRequired,
  id: PropTypes.number.isRequired
}

export default SolidTooltip
export { TooltipContent }
