import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import * as tf from '@tensorflow/tfjs'

import emotions from '../../emotions.json'

import { log, dir } from '../Utils/Console'

async function loadModels() {
  let models
  try {
    /* models = emotions.map(emotion => ({
      [emotion]: tf.loadLayersModel(`/models/${emotion}/model.json`)
    })) */
    /*
    models = emotions.map(emotion => tf.loadLayersModel(`/models/${emotion}/model.json`))
    models = await Promise.all(models)
*/
    models = await Promise.all(
      emotions.map(emotion =>
        tf.loadLayersModel(`/models/${emotion}/model.json`).then(model => ({ [emotion]: model }))
      )
    )
    log('%c[loadModels] finished loading:', 'font-weight:bold; color:green')
    dir(models)
  } catch (err) {
    // console.log(err)
  }
  return models
}

const NeuralNetworksContext = React.createContext()

function NeuralNetworksProvider(props) {
  const { children } = props

  // The object that holds the AuthenticatedPerson reply from the API
  const [models, setModels] = useState([])
  const [hasModels, setHasModels] = React.useState(false)

  useEffect(() => {
    setHasModels(models.length > 0)
  }, [models])

  useEffect(() => {
    tf.ready().then(() => {
      loadModels().then(l => {
        setModels(l)
        log('%c[NeuralNetworksProvider] Models is:', 'font-weight:bold; color:green')
        dir(l)
      })
    })
  }, [])

  return (
    <NeuralNetworksContext.Provider value={[models, hasModels]}>
      {children}
    </NeuralNetworksContext.Provider>
  )
}

NeuralNetworksProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

function useNeuralNetworksContext() {
  const [models, hasModels] = React.useContext(NeuralNetworksContext)
  return [models, hasModels]
}

export { useNeuralNetworksContext, NeuralNetworksProvider }
