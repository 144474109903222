import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'

import ExampleList from '../Twitter/ExampleList'

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: theme.spacing(2)
  }
}))

function TryAnotherDialog(props) {
  const classes = useStyles()
  const { onClose, open } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{ className: classes.dialog }}
    >
      <ExampleList onClick={handleClose} />
    </Dialog>
  )
}

TryAnotherDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default TryAnotherDialog
