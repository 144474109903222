import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { withStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Hidden, Typography, List, ListItem, Fade } from '@material-ui/core'
import TwitterIcon from '@material-ui/icons/Twitter'

import tweets from '../API/tweets.json'

const RoundListItem = withStyles(theme => ({
  root: {
    borderRadius: theme.spacing(2),
    '&$focusVisible': {
      backgroundColor: 'transparent'
    },
    '&$selected': {
      backgroundColor: 'transparent'
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  focusVisible: {},
  selected: {
    color: theme.palette.primary.main
  }
}))(ListItem)

const PaddedTypography = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))(Typography)

const PaddedTitle = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2)
  }
}))(Typography)

const SmallTwitterIcon = withStyles(theme => ({
  root: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    marginRight: theme.spacing(1)
  }
}))(TwitterIcon)

function Examples({ selected, onClick }) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))
  return (
    <>
      <Hidden mdDown>
        <Fade in>
          <PaddedTitle variant="subtitle2" component="h2" paragraph>
            Usage:
          </PaddedTitle>
        </Fade>
      </Hidden>
      <Fade in>
        <PaddedTypography variant="body2" color="textSecondary" paragraph>
          Paste a tweet URL on the top bar or select one of the quick links below
        </PaddedTypography>
      </Fade>
      <List dense={matches} disablePadding component="nav" aria-label="main mailbox folders">
        {tweets.map((tweet, i) => (
          <Fade key={tweet.data.id} in style={{ transitionDelay: `${20 * i}ms` }}>
            <RoundListItem
              button
              focusRipple
              component={RouterLink}
              to={`/examples/${tweet.data.id}`}
              onClick={onClick}
              selected={selected === tweet.data.id}
            >
              <SmallTwitterIcon color={selected === tweet.data.id ? 'primary' : 'inherit'} />
              <Typography variant={matches ? 'caption' : 'body1'}>
                [{tweet.data.lang}] {tweet.includes.users[0].name}
              </Typography>
            </RoundListItem>
          </Fade>
        ))}
      </List>
    </>
  )
}

Examples.propTypes = {
  selected: PropTypes.string,
  onClick: PropTypes.func
}

Examples.defaultProps = {
  selected: '',
  onClick: () => {}
}

export default Examples
