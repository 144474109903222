import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography, Fade } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

function Value(props) {
  const { children } = props
  return (
    <Fade in>
      <Typography variant="h4" component="p" color="textSecondary">
        {children}
      </Typography>
    </Fade>
  )
}

Value.propTypes = {
  children: PropTypes.node.isRequired
}

const useStyles = makeStyles(theme => ({
  item: {
    opacity: 0.33,
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('xs')]: { textAlign: 'center' }
  },
  small: { fontSize: 13 }
}))

function Legend() {
  const classes = useStyles()

  return (
    <Grid item xs={4} sm={3} md={2} lg={1} className={classes.item}>
      <Fade in>
        <Typography variant="caption" component="p">
          [emotion]
        </Typography>
      </Fade>
      <Fade in>
        <Typography variant="caption" component="p" className={classes.small}>
          in sentences
        </Typography>
      </Fade>
      <Fade in>
        <Value>prob</Value>
      </Fade>
    </Grid>
  )
}

export default Legend
