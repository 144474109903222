import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const emoji = {
  anger: '😡',
  anticipation: '😉',
  disgust: '🤮',
  fear: '😨',
  joy: '😂',
  love: '🥰',
  optimism: '😊',
  pessimism: '😞',
  sadness: '😥',
  surprise: '😯',
  trust: '😌'
}

const unknown = [
  "Couldn't tell",
  'Nothing concrete',
  'This one appears netural',
  "I can't infer any",
  'Seems emotionless',
  "Can't say",
  'Nothing stands out'
]

const useStyles = makeStyles(theme => ({
  section: {
    padding: theme.spacing(2),
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(4) },
    [theme.breakpoints.down('xs')]: { textAlign: 'center' }
  },
  container: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: { justifyContent: 'flex-start' }
  },
  item: { padding: theme.spacing(2, 0) },
  icon: {
    height: theme.spacing(2),
    width: theme.spacing(2)
  },
  divider: {
    width: '10%',
    height: 2,
    margin: theme.spacing(2, 'auto', 2, 0),
    [theme.breakpoints.down('xs')]: { margin: theme.spacing(2, 'auto') }
  }
}))

function percentage(value) {
  return new Intl.NumberFormat('en-US', {
    style: 'percent'
  }).format(value)
}

const displayable = emotions =>
  Object.entries(emotions)
    .filter(([, val]) => val >= 0.5)
    .sort(([, a], [, b]) => b - a)

function Ranking(props) {
  const classes = useStyles()
  const { sentences, predictions } = props

  const dim = predictions.length
  const hasPredictions = dim > 0

  return (
    <section className={classes.section}>
      {hasPredictions && (
        <Typography variant="subtitle2" paragraph>
          Emotion in sentences:
        </Typography>
      )}
      {hasPredictions &&
        sentences.map((sentence, i) => (
          <React.Fragment key={sentence}>
            <Typography variant="caption" dangerouslySetInnerHTML={{ __html: sentence }} />
            {displayable(predictions[i]).length ? (
              displayable(predictions[i]).map(([emotion, value]) => (
                <Typography
                  key={emotion}
                  variant={value >= 0.7 ? 'subtitle2' : 'body2'}
                  color={value >= 0.7 ? 'primary' : 'textPrimary'}
                >{`${percentage(value)} probability of ${emotion} ${emoji[emotion]}`}</Typography>
              ))
            ) : (
              <Typography variant="body2">
                {unknown[Math.floor(Math.random() * unknown.length)]}
              </Typography>
            )}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
    </section>
  )
}

Ranking.propTypes = {
  sentences: PropTypes.arrayOf(PropTypes.string),
  predictions: PropTypes.arrayOf(PropTypes.shape({}))
}

Ranking.defaultProps = {
  // predictions: [emotions.reduce((prev, next) => ({ ...prev, [next]: 0 }), {})]
  sentences: [],
  predictions: []
}

export default Ranking
