import React from 'react'

import { Link as RouterLink } from 'react-router-dom'

import { Typography, Link } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const PaddedTypography = withStyles(() => ({
  root: {
    // paddingLeft: theme.spacing(4),
    // paddingRight: theme.spacing(4)
  }
}))(Typography)

const Footer = () => (
  <PaddedTypography variant="caption" component="p">
    This demonstration performs multi-languange sentiment analysis using neural networks and zero
    shot cross lingual transfer learning.{' '}
    <Link component={RouterLink} to="/about">
      Really? Tell me more!
    </Link>
  </PaddedTypography>
)

export default Footer
