/* eslint-disable no-console */

const isDev = process.env.NODE_ENV === 'development'
const noOp = () => {}
const log = isDev ? console.log : noOp
const warn = isDev ? console.warn : noOp
const error = isDev ? console.error : noOp
const dir = isDev ? console.dir : noOp

export { log, warn, error, dir }
