import axios from 'axios'

import { log } from '../Utils/Console'

const LASER_ENDPOINT =
  'https://us-central1-laser-sentence-embeddings.cloudfunctions.net/get_sentence_embeddings'

// Fetch logic
const postRequest = async (req, source) => {
  let response
  try {
    const result = await axios.post(
      `${LASER_ENDPOINT}`,
      { text: req },
      {
        cancelToken: source.token,
        headers: { 'X-API-KEY': process.env.REACT_APP_APIKEY, 'Content-type': 'application/json' }
      }
    )
    if (result.data) {
      response = result.data
    }
  } catch (exception) {
    log(exception)
  }
  return response
}

export default postRequest
