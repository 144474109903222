import React from 'react'

import { Box, Typography, Button, Fade } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import LegendDialog from './LegendDialog'

const PaddedTypography = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))(Typography)

const SpacedButton = withStyles(theme => ({
  root: {
    margin: theme.spacing(2)
  }
}))(Button)

function Legend() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Fade in>
      <Box component="section" textAlign="right">
        <PaddedTypography variant="overline" align="right" component="h2">
          Legend
        </PaddedTypography>
        <PaddedTypography variant="body2" color="textSecondary" align="right">
          What are these numbers?
        </PaddedTypography>
        <SpacedButton
          color="primary"
          aria-label="show the legend"
          onClick={handleClickOpen}
          variant="contained"
          disableElevation
          size="small"
          to="/about"
        >
          Show me
        </SpacedButton>
        <LegendDialog open={open} onClose={handleClose} />
      </Box>
    </Fade>
  )
}

export default Legend
