import { useEffect, useLayoutEffect, useState } from 'react'

import axios from 'axios'
import * as tf from '@tensorflow/tfjs'

import { useNeuralNetworksContext } from '../Context/NeuralNetworks'

// import embeddings from '../Prediction/embeddings.json'
import tweets from './tweets.json'

import postText from './postText'
import getTweet from './getTweet'

import { log } from '../Utils/Console'

const { CancelToken } = axios

function transposeEmotionsToSentences(list) {
  const dim = Object.values(list[0])[0].length
  // console.log(dim)
  const result = []

  for (let i = 0; i < dim; i += 1) {
    // result.push(list.map(emotion => Object.values(emotion)[0][i]))

    const transformed = list.reduce((prev, next) => {
      const [[key, value]] = Object.entries(next)
      return { ...prev, [key]: value[i] }
    }, {})

    result.push(transformed)
  }
  // console.log(result)
  return result
}

const EMPTY_TWEET = { data: undefined, includes: undefined }

function useTwitter(tid, isLive) {
  const [error, setError] = useState(false)

  // Use loaded neural networks
  const [models, hasModels] = useNeuralNetworksContext()

  // Fetch tweet data:
  const [tweet, setTweet] = useState(EMPTY_TWEET)
  // if isLive -> use twitter API
  // else -> get data from json
  useEffect(() => {
    const source = CancelToken.source()
    // console.log(`tid: ${tid} and isLive: ${isLive}`)
    if (tid && isLive) {
      // Get the tweet
      log('%c[TweetPage] fetching tweet', 'font-weight:bold; color:blue')
      getTweet(tid, source)
        .then(res => {
          if (res) {
            if (res.data && res.includes) {
              log('%c[TweetPage] saving tweet', 'font-weight:bold; color:blue')
              // console.log(res)
              setTweet(res)
            } else {
              log('%c[TweetPage] error in getTweet response', 'font-weight:bold; color:blue')
              setError(true)
            }
          } else {
            log('%c[TweetPage] empty response in getTweet', 'font-weight:bold; color:blue')
          }
        })
        .catch(() => {
          log('%c[TweetPage] error calling getTweet', 'font-weight:bold; color:blue')
          setError(true)
        })
    } else {
      log('%c[TweetPage] loading example', 'font-weight:bold; color:blue')
      const example = tweets.find(el => el.data.id === tid)
      setTweet(example)
    }

    // Cleanup: cancel any outstanding api call
    return () => {
      source.cancel('twitter fetch canceled')
    }
  }, [tid, isLive])

  // Process the tweet for sentences and embeddings
  // Make call to lambda python function in google cloud functions
  const [sentences, setSentences] = useState([])
  const [embeddings, setEmbeddings] = useState([])
  const [length, setLength] = useState(0)
  // console.log(sentences)
  // console.log(embeddings.length)
  log(length)

  useEffect(() => {
    const source = CancelToken.source()
    if (tweet.data?.text) {
      postText(tweet.data.text, source)
        .then(res => {
          if (res) {
            log('%c[TweetPage] saving tokenized sentences', 'font-weight:bold; color:blue')
            setSentences(res.sentences)
            setEmbeddings(res.embeddings)
            setLength(res.length)
            // console.log(res)
          } else {
            log('%c[TweetPage] error in postText response', 'font-weight:bold; color:blue')
          }
        })
        .catch(() => {
          log('%c[TweetPage] error calling postText', 'font-weight:bold; color:blue')
          setError(true)
        })
    }

    // Cleanup: cancel any outstanding api call
    return () => {
      source.cancel('sentence and embeddings fetch canceled')
    }
  }, [tweet])

  // Make a prediction from the sentences
  const [predictions, setPredictions] = useState()
  // console.log(predictions)

  useEffect(() => {
    if (tid && embeddings.length > 0 && hasModels && !predictions) {
      // console.log('models:')
      // console.dir(models)
      // console.dir(embeddings.array)
      /*
      const predictions = models.map(model =>
        Object.entries(model).forEach(([key, value]) => ({
          [key]: value.then(res => res.predict(tf.tensor(embeddings.array)))
        }))
      )
      Promise.all(Object.values(predictions)).then(data => setPrediction(transpose(data)))
      */
      /*
      const predictions = models.map(model => model.predict(tf.tensor(embeddings.array)))
      Promise.all(predictions).then(data => setPrediction(transpose(data)))
      */

      // console.log('embeddings:')
      // console.log(embeddings)

      Promise.all(
        models.map(model => {
          const [[key, value]] = Object.entries(model)
          // console.log(key)
          // console.log(value)
          // console.log(value.predict(tf.tensor(embeddings.array)))
          return value
            .predict(tf.tensor(embeddings))
            .data()
            .then(prob => ({ [key]: prob }))
        })
      )
        .then(res => {
          // console.log(res)
          setPredictions(transposeEmotionsToSentences(res))
          log('%c[TweetPage] saving predictions', 'font-weight:bold; color:blue')
        })
        .catch(err => {
          log(`Error in promises ${err}`)
          setError(true)
        })
    }
  }, [tid, hasModels, models, embeddings, predictions])

  function clearState() {
    setTweet(EMPTY_TWEET)
    setSentences([])
    setEmbeddings([])
    setLength(0)
    setPredictions()
    setError(false)
  }

  useLayoutEffect(() => {
    clearState()
    log('%c[TweetPage] clearing state', 'font-weight:bold; color:orange')
  }, [tid])

  return [tweet, sentences, predictions, error]
}

export default useTwitter
